import type { DzTitleMoleculeProps, DzTitleSectionProps } from '@zwirner/design-system'
import { DzTitleMoleculeTypes } from '@zwirner/design-system'

import { EXPLORE_MORE } from '@/common/constants/commonCopies'
import { DzTitleMolecule } from '@/components/wrappers/DzTitleMoleculeWrapper'
import type { MoleculeTitleFieldsSchemaType } from '@/sanity/queries/components/builders/moleculeTitleBuilder'

type Props = {
  data: MoleculeTitleFieldsSchemaType
}

export const moleculeHeaderMapper = (
  data: MoleculeTitleFieldsSchemaType
): DzTitleMoleculeProps['data'] | null => {
  if (!data?.enabled) return null

  const linkCTA: DzTitleSectionProps['linkCTA'] = data.cta?.link?.href
    ? {
        text: data.cta.text ?? EXPLORE_MORE,
        url: data.cta.link.href,
        linkProps: {
          openNewTab: data.cta.link.blank,
        },
      }
    : null

  return {
    title: data.title,
    subtitle: data.subtitle,
    customClass: 'md:mb-0 mb-0',
    linkCTA,
    // temp solution until we will decouple title with type molecule for explicit usage in page builder.
    charLimit: 200,
  }
}

export const DzMoleculeTitle = ({ data }: Props) => {
  const mappedData = moleculeHeaderMapper(data)
  if (!mappedData) return null

  return <DzTitleMolecule data={mappedData} type={DzTitleMoleculeTypes.MOLECULE} />
}
