import { DzCard as DzCardBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzCardProps = ComponentProps<typeof DzCardBasic>
type Props = Omit<DzCardProps, 'LinkElement'>

export const DzCard = (props: Props) => {
  const dzCardProps = { ...props, LinkElement: Link } as DzCardProps
  return <DzCardBasic {...dzCardProps} />
}

DzCard.displayName = 'DzCardWrapper'
