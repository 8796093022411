import { DzImageMosaic as DzImageMosaicBase } from '@/components/containers/galleries/components/DzImageMosaic'
import type { DzImageMosaicPropsDataType } from '@/sanity/queries/components/dzImageMosaicProps'

type DzImageMosaicProps = {
  data: any
  componentProps: DzImageMosaicPropsDataType
  parentId: string
}

export const DzImageMosaic = ({ componentProps }: DzImageMosaicProps) => {
  return <DzImageMosaicBase elements={componentProps.elements} />
}

DzImageMosaic.notContentDependant = true
