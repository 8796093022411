import type { DzEditorialDataType } from '@zwirner/design-system'

import { DzEditorial as DzEditorialMolecule } from '@/components/wrappers/DzEditorialWrapper'
import type { DzEditorialSchemaProps } from '@/sanity/types'

import { contentTypesMapper, dzEditorialOverrides } from './editorialMapper'

export const EDITORIAL_TEXT_TYPES = {
  PARAGRAPH: 'paragraph',
  QUOTE: 'quote',
}

export const EDITORIAL_TEXT_NAMES = [
  EDITORIAL_TEXT_TYPES.PARAGRAPH,
  EDITORIAL_TEXT_TYPES.QUOTE,
] as const

type DzEditorialProps = {
  data: DzEditorialDataType & { _type?: keyof typeof contentTypesMapper }
  componentProps: DzEditorialSchemaProps
  parentId: string
}

export const DzEditorial = ({ data, componentProps }: DzEditorialProps) => {
  const { _type } = data ?? {}
  const mappedData = _type ? contentTypesMapper[_type]?.(data) : data
  const overrideData = dzEditorialOverrides(componentProps) ?? {}

  return <DzEditorialMolecule {...{ ...mappedData, ...overrideData }} />
}

DzEditorial.notContentDependant = true
export default DzEditorial
