import type { DzButtonProps } from '@zwirner/design-system'
import {
  BUTTON_VARIANTS,
  ButtonModes,
  CardTypes,
  INQUIRY_CATEGORIES,
  matchInternalPath,
} from '@zwirner/design-system'
import Router from 'next/router'

import type { PopUpInfo } from '@/common/api/campaigns/campaigns'
import type { RegisteredInterestModalProps } from '@/common/components/popups/modalTriggerListener/useRegisteredInterestFormModal'
import { CTA_TEXT } from '@/common/constants/cart'
import {
  INQUIRE,
  LEARN_MORE,
  PURCHASE,
  REQUEST_A_PREVIEW,
  TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED,
} from '@/common/constants/commonCopies'
import { GTMExitLinkEvent } from '@/common/utils/gtm/gtmExitLinkEvent'
import { safeText } from '@/common/utilsMappers/safe'
import type { InquireModalProps } from '@/components/hooks/useOpenInquiryDispatch'
import { PageBuilderComponents } from '@/components/pageBuilder/types'
import { ModalTriggerEvent, ModalTriggerTypes } from '@/events/ModalTriggerEvent'
import type { CTABuilderType } from '@/sanity/queries/components/builders/ctaBuilder'
import { CTAActionTypes, ModalTypes } from '@/sanity/types'

export type RegisteredInterestPopupEventDetails = {
  modalType: ModalTypes.REGISTERED_INTEREST
  props: RegisteredInterestModalProps
} & (
  | {
      popupInfo: PopUpInfo
      triggerType: ModalTriggerTypes.POPUP
    }
  | {
      triggerType: ModalTriggerTypes.CTA
      popupInfo?: never
    }
)

export type InquiryPopupEventDetails = {
  modalType: ModalTypes.INQUIRE
  props: InquireModalProps
} & (
  | {
      popupInfo: PopUpInfo
      triggerType: ModalTriggerTypes.POPUP
    }
  | {
      triggerType: ModalTriggerTypes.CTA
      popupInfo?: never
    }
)

export enum CTA_CLICK_SOURCE {
  INTERSTITIAL = 'INTERSTITIAL',
}

const externalFlags = ['www.', 'https://', '.com']
const internalFlags = ['.davidzwirner.com', '.zwirner.dev', '.zwirner.tech']

export type CTAOptions = {
  asLink: boolean
  source?: string
  modalProps?: Record<string, any>
}
export type CTAMapperPropsType = {
  data: CTABuilderType | null | undefined
  options: CTAOptions
}

export enum MappedCTATypes {
  LINK = 'Link',
  BUTTON = 'Button',
}

type MappedCTA = {
  type: MappedCTATypes
  supported: boolean
  text?: string
  onClick?: DzButtonProps['onClick']
  url?: string | null
  openNewTab?: boolean | null
  disabled?: boolean
}

type CtaTransformerByMoleculeType = typeof ctaTransformerByMolecule

const ctaTransformerByMolecule = {
  [PageBuilderComponents.dzCard]: (cta: MappedCTA, cardType: CardTypes = CardTypes.CONTENT) => {
    const { text = LEARN_MORE, url, onClick, openNewTab = false, disabled = false } = cta
    if (cardType === CardTypes.PRODUCT || cardType === CardTypes.ARTWORK) {
      return {
        text,
        ctaProps: {
          onClick,
          disabled: disabled ?? false,
        },
      }
    }
    if (cta.type === MappedCTATypes.LINK && url) {
      return {
        linkCTA: {
          text,
          url,
          linkProps: {
            openNewTab: !!openNewTab,
          },
        },
      }
    }
    if (cta.type === MappedCTATypes.BUTTON && onClick) {
      return {
        primaryCTA: {
          text,
          ctaProps: {
            variant: BUTTON_VARIANTS.PRIMARY,
            onClick,
            disabled: disabled ?? false,
          },
        },
      }
    }
  },
  [PageBuilderComponents.dzHero]: (cta: MappedCTA) => {
    const { text = LEARN_MORE, url, onClick, openNewTab = false, disabled = false } = cta
    if (cta.type === MappedCTATypes.LINK && url) {
      return {
        linkCTA: {
          text,
          url,
          linkProps: {
            openNewTab: !!openNewTab,
          },
        },
      }
    }
    if (cta.type === MappedCTATypes.BUTTON && onClick) {
      return {
        buttonCTA: {
          text,
          ctaProps: {
            variant: BUTTON_VARIANTS.PRIMARY,
            onClick,
            disabled: disabled ?? false,
          },
        },
      }
    }
  },
  [PageBuilderComponents.dzInterstitial]: (cta: MappedCTA) => {
    const { text = LEARN_MORE, onClick, disabled = false } = cta
    // By default, interstitial only have buttons

    if (!onClick) return

    return {
      primaryCta: {
        text,
        ctaProps: {
          variant: BUTTON_VARIANTS.PRIMARY,
          onClick,
          disabled: disabled ?? false,
        },
      },
    }
  },
  [PageBuilderComponents.dzSplit]: (cta: MappedCTA) => {
    const { text = LEARN_MORE, url, onClick, openNewTab = false, disabled = false } = cta
    if (cta.type === MappedCTATypes.LINK && url) {
      return {
        linkCTA: {
          text,
          url,
          linkProps: {
            openNewTab: !!openNewTab,
          },
        },
      }
    }
    if (cta.type === MappedCTATypes.BUTTON && onClick) {
      return {
        buttonCTA: {
          text,
          ctaProps: {
            variant: BUTTON_VARIANTS.PRIMARY,
            mode: ButtonModes.DARK,
            onClick,
            disabled: disabled ?? false,
          },
        },
      }
    }
  },
} as const

const DEFAULT_DISABLED_CTA: MappedCTA = {
  type: MappedCTATypes.BUTTON,
  supported: false,
}

export type CtaActionsNames = (typeof CTAActionTypes)[keyof typeof CTAActionTypes]

const ctaMapperByType: Record<CtaActionsNames, (data: CTAMapperPropsType) => MappedCTA> = {
  [CTAActionTypes.INQUIRE]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.INQUIRE) return DEFAULT_DISABLED_CTA
    const { source, modalProps } = options
    const { text } = data
    const textWithFallback = text || INQUIRE
    // This overrides title, subtitles & inquiryCategory from the popup
    // If we need to include other titles for popups lets improve this logic
    const sourceProps =
      source === CTA_CLICK_SOURCE.INTERSTITIAL
        ? {
            title: INQUIRE,
            subtitle: TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED,
            inquiryCategory: INQUIRY_CATEGORIES.GENERAL,
          }
        : {}

    const mappedModalProps = modalProps ?? {}
    if (!modalProps?.contextData?.ctaText) {
      mappedModalProps.contextData = {
        ...(mappedModalProps?.contextData ?? {}),
        ctaText: text || INQUIRE, //ctaText is required for inquire
      }
    }

    return {
      type: MappedCTATypes.BUTTON,
      supported: true,
      text: textWithFallback,
      onClick: () => {
        window.document.dispatchEvent(
          ModalTriggerEvent({
            modalType: ModalTypes.INQUIRE,
            props: { ...mappedModalProps, ...sourceProps } as InquireModalProps,
            triggerType: ModalTriggerTypes.CTA,
          } satisfies InquiryPopupEventDetails)
        )
      },
    }
  },
  [CTAActionTypes.REGISTERED_INTEREST]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.REGISTERED_INTEREST) return DEFAULT_DISABLED_CTA
    const { source, modalProps } = options
    const { text } = data
    const textWithFallback = text || LEARN_MORE

    let sourceProps = {}
    if (source === CTA_CLICK_SOURCE.INTERSTITIAL) {
      const portableTextSubtitle = safeText({
        key: 'subtitle',
        text: modalProps?.registeredInterest?.description,
      })

      sourceProps = {
        title: modalProps?.registeredInterest?.title,
        ...portableTextSubtitle,
        inquiryCategory: INQUIRY_CATEGORIES.REGISTERED_INTEREST,
        primaryCTA: { text },
      }
    }

    return {
      type: MappedCTATypes.BUTTON,
      supported: true,
      text: textWithFallback,
      onClick: () => {
        window.document.dispatchEvent(
          ModalTriggerEvent({
            modalType: ModalTypes.REGISTERED_INTEREST,
            props: { ...modalProps, ...sourceProps } as RegisteredInterestModalProps,
            triggerType: ModalTriggerTypes.CTA,
          } satisfies RegisteredInterestPopupEventDetails)
        )
      },
    }
  },
  [CTAActionTypes.SOLD_OUT]: ({ data }) => {
    return {
      type: MappedCTATypes.BUTTON,
      supported: true,
      disabled: true,
      text: data?.text || CTA_TEXT.SOLD_OUT,
    }
  },
  [CTAActionTypes.REQUEST_A_PREVIEW]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.REQUEST_A_PREVIEW) return DEFAULT_DISABLED_CTA
    const { source, modalProps } = options
    const { text } = data
    const textWithFallback = text || LEARN_MORE
    // This overrides title, subtitles & inquiryCategory from the popup
    // If we need to include other titles for popups lets improve this logic
    const sourceProps =
      source === CTA_CLICK_SOURCE.INTERSTITIAL
        ? {
            title: INQUIRE,
            subtitle: TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED,
            inquiryCategory: INQUIRY_CATEGORIES.REQUEST_PREVIEW,
          }
        : {}

    const mappedModalProps = modalProps ?? {}
    if (!modalProps?.contextData?.ctaText) {
      mappedModalProps.contextData = {
        ...(mappedModalProps?.contextData ?? {}),
        ctaText: text || REQUEST_A_PREVIEW, //ctaText is required for inquire
      }
    }

    return {
      type: MappedCTATypes.BUTTON,
      supported: true,
      text: textWithFallback,
      onClick: () => {
        window.document.dispatchEvent(
          ModalTriggerEvent({
            modalType: ModalTypes.REQUEST_A_PREVIEW,
            props: {
              ...mappedModalProps,
              ...sourceProps,
            },
            triggerType: ModalTriggerTypes.CTA,
          })
        )
      },
    }
  },
  [CTAActionTypes.ECOMM]: ({ data }) => {
    if (!data || data.action !== CTAActionTypes.ECOMM) return DEFAULT_DISABLED_CTA
    const { text } = data
    // While we use Shopify buy now option to do ECOMM this logic is not needed
    // const {ecommProps} = options
    // const {ecommAction} = options
    // if (!ecommProps || !ecommAction) return DEFAULT_DISABLED_CTA
    const textWithFallback = text || PURCHASE
    return {
      // By default all ecomm options are buttons
      type: MappedCTATypes.BUTTON,
      text: textWithFallback,
      // onClick: () => {
      //   ecommAction(ecommProps)
      // },
      // While we use Shopify buy now option to do ECOMM this will be disabled
      supported: false,
    }
  },
  [CTAActionTypes.NONE]: () => {
    return DEFAULT_DISABLED_CTA
  },
  [CTAActionTypes.NEWSLETTER]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.NEWSLETTER) return DEFAULT_DISABLED_CTA
    const { modalProps } = options
    const { text } = data
    const textWithFallback = text || LEARN_MORE
    // This overrides title, subtitles & inquiryCategory from the popup
    // If we need to include other titles for popups lets improve this logic

    return {
      type: MappedCTATypes.BUTTON,
      supported: true,
      text: textWithFallback,
      onClick: () => {
        window.document.dispatchEvent(
          ModalTriggerEvent({
            modalType: ModalTypes.NEWSLETTER,
            props: { ...modalProps },
            triggerType: ModalTriggerTypes.CTA,
          })
        )
      },
    }
  },
  [CTAActionTypes.LINK]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.LINK) return DEFAULT_DISABLED_CTA
    const { text, link } = data
    const textWithFallback = text || LEARN_MORE
    if (!link) return DEFAULT_DISABLED_CTA
    const { href, blank } = link
    return {
      type: options?.asLink ? MappedCTATypes.LINK : MappedCTATypes.BUTTON,
      supported: true,
      text: textWithFallback,
      onClick: () => {
        if (!Router || !href) return
        // Check if the URL is external
        if (!blank) return Router.push(href)

        if (
          externalFlags.some((e) => href.includes(e)) &&
          !internalFlags.some((e) => href.includes(e))
        ) {
          GTMExitLinkEvent(textWithFallback, href)
          return window.open(href, '_blank')
        }
        return Router.push(href)
      },
      url: href,
      openNewTab: blank,
    }
  },
  [CTAActionTypes.DOWNLOAD_PDF]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.DOWNLOAD_PDF) return DEFAULT_DISABLED_CTA
    const { text, file } = data
    const textWithFallback = text || LEARN_MORE
    if (!file) return DEFAULT_DISABLED_CTA
    const { asset } = file
    const { url } = asset
    if (!url) return DEFAULT_DISABLED_CTA

    return {
      type: options?.asLink ? MappedCTATypes.LINK : MappedCTATypes.BUTTON,
      onClick: () => {
        GTMExitLinkEvent(textWithFallback, url)
        return window.open(url, '_blank')
      },
      // By default download docs should open new tabs
      openNewTab: true,
      url,
      text: textWithFallback,
      supported: true,
    }
  },
  [CTAActionTypes.LINK_CONTENT]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.LINK_CONTENT) return DEFAULT_DISABLED_CTA
    const { text, url } = data
    if (!url) return DEFAULT_DISABLED_CTA
    const textWithFallback = text || LEARN_MORE
    return {
      type: options?.asLink ? MappedCTATypes.LINK : MappedCTATypes.BUTTON,
      supported: true,
      onClick: () => {
        if (!Router || !url) return
        if (matchInternalPath(url)) {
          return Router.push(url)
        } else {
          return Router.replace(url)
        }
      },
      // By default internal links open in the same tab
      openNewTab: false,
      text: textWithFallback,
      url,
    }
  },
}

export const singleUnitMapper = (props: CTAMapperPropsType) => {
  if (!props || !props.data) return null
  const ctaMapper = ctaMapperByType[props.data.action]
  if (!ctaMapper) return null
  return ctaMapper(props)
}

export const simpleMoleculeMapper = (component: keyof CtaTransformerByMoleculeType) => {
  return ctaTransformerByMolecule[component]
}

export const ctaMapperByMoleculeType = <T extends keyof CtaTransformerByMoleculeType>(
  component: T
) => {
  return (
    props: CTAMapperPropsType,
    cardType?: T extends PageBuilderComponents.dzCard ? CardTypes : never
  ): ReturnType<CtaTransformerByMoleculeType[T]> | null => {
    const transformedCTA = singleUnitMapper(props)
    if (!transformedCTA || !transformedCTA.supported) return null

    const transformer = ctaTransformerByMolecule[component]

    if (component === PageBuilderComponents.dzCard && cardType) {
      return transformer(transformedCTA, cardType || CardTypes.CONTENT) as ReturnType<
        CtaTransformerByMoleculeType[T]
      >
    }

    return transformer(transformedCTA) as ReturnType<CtaTransformerByMoleculeType[T]>
  }
}
