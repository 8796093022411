import Image from 'next/image'

import {
  EXHIBITION,
  EXHIBITIONS_URL,
  LEARN_MORE,
  ONLINE,
  ONLINE_EXHIBITION_TITLE,
} from '@/common/constants/commonCopies'
import { mapStatus } from '@/common/utilsMappers/date.mapper'
import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { safeText } from '@/common/utilsMappers/safe'
import { MediaBuilderTypes } from '@/sanity/queries/components/builders/mediaBuilder'
import type { FieldsPerTypeSchemaType } from '@/sanity/queries/components/componentTypesData'
import type { DzSplitTypeExtendedProps } from '@/sanity/types'

type exhibitionMapperArgs = {
  data: Extract<
    FieldsPerTypeSchemaType,
    { _type: 'exhibitionPage' | 'onlineExhibitionPage' | 'exceptionalWork' }
  >
  props: DzSplitTypeExtendedProps
}

export const exhibitionMapper = ({ data, props }: exhibitionMapperArgs) => {
  const { splitType, reverse } = props ?? {}
  const { artists, title, slug, heroMedia, cardViewMedia, subtitle } = data
  const [primaryArtist] = artists ?? []
  const { fullName } = primaryArtist ?? {}

  const heroMediaSource =
    Object.keys(heroMedia ?? {}).length > 0 && heroMedia?.type !== MediaBuilderTypes.UNSET
      ? heroMedia
      : null
  const cardViewMediaSource =
    Object.keys(cardViewMedia ?? {}).length > 0 &&
    cardViewMedia?.type == MediaBuilderTypes.IMAGE &&
    cardViewMedia?.image
      ? cardViewMedia
      : null

  const { media } = dzMediaMapper({
    data: cardViewMediaSource ?? heroMediaSource ?? data,
    ImgElement: Image,
  })
  const { status } = mapStatus(data)

  let locationName = ONLINE
  if (data._type === 'exhibitionPage') {
    const [primaryLocation] = data.locations ?? []
    const { name } = primaryLocation ?? {}
    locationName = name
  }

  let description: string | any[] = ''
  if (data._type === 'exceptionalWork') description = data.description ?? ''
  if (data._type !== 'exceptionalWork') description = data.summary ?? ''

  const descriptionText = safeText({ key: 'description', text: description })

  let eyebrow = data.eyebrow
  if (data._type === 'exhibitionPage') eyebrow = eyebrow ?? EXHIBITION
  if (data._type !== 'exhibitionPage') eyebrow = eyebrow ?? ONLINE_EXHIBITION_TITLE

  return {
    type: splitType,
    reverse,
    data: {
      media,
      category: eyebrow,
      title: title ?? fullName,
      ...descriptionText,
      subtitle,
      secondaryTitle: locationName,
      secondarySubtitle: status,
      linkCTA: {
        text: LEARN_MORE,
        url: slug.current ?? EXHIBITIONS_URL,
      },
    },
  }
}
