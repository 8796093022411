import { DzLink as DzLinkBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzLinkProps = ComponentProps<typeof DzLinkBasic>
type Props = Omit<DzLinkProps, 'LinkElement'>

export const DzLink = (props: Props) => {
  const dzLinkProps = { ...props, LinkElement: Link } as DzLinkProps
  return <DzLinkBasic {...dzLinkProps} />
}

DzLink.displayName = 'DzLinkWrapper'
