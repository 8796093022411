import { CardTypes } from '@zwirner/design-system'
import Image from 'next/image'

import { ctaMapperByMoleculeType } from '@/common/utilsMappers/cta.mapper'
import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { createPortableTextElement } from '@/common/utilsMappers/safe'
import { createInquireModalArtworkProps } from '@/components/hooks/useOpenInquiryDispatch'
import { PageBuilderComponents } from '@/components/pageBuilder/types'
import { ArtworkFramedSchema } from '@/sanity/queries/components/content/artworkContent'
import type { DzCardExtendedProps } from '@/sanity/types'

import { contentLinkCTAMapper, valueOrUndefined } from './utils'

const cardCTAMapper = ctaMapperByMoleculeType(PageBuilderComponents.dzCard)

export const dzCardOverrides = (props: DzCardExtendedProps, type: CardTypes, data: any) => {
  const { mediaOverride, enableOverrides } = props ?? {}
  if (!enableOverrides) return {}

  const { media, hideImage } = dzMediaMapper({
    data: mediaOverride,
    ImgElement: Image,
  })

  const linkCTA = contentLinkCTAMapper(props.linkCTA)

  switch (type) {
    case CardTypes.PRODUCT:
      const productCtaOverrides = {
        primaryCTA: cardCTAMapper(
          {
            data: props.primaryCTA,
            options: {
              asLink: false,
            },
          },
          CardTypes.PRODUCT
        ),
        secondaryCTA: cardCTAMapper(
          {
            data: props.secondaryCTA,
            options: {
              asLink: false,
            },
          },
          CardTypes.PRODUCT
        ),
      }
      return {
        data: {
          media,
          hideImage,
          title: valueOrUndefined(props.primaryTitle),
          primarySubtitle: valueOrUndefined(props.primarySubtitle),
          detail1: valueOrUndefined(props.detail1),
          price: valueOrUndefined(props.productPrice),
          description: props.descriptionOverride
            ? createPortableTextElement({ text: props.descriptionOverride })
            : undefined,
          tagline: props.descriptionOverride
            ? createPortableTextElement({ text: props.taglineOverride })
            : undefined,
          ...productCtaOverrides,
          ...linkCTA,
        },
      }
    case CardTypes.CONTENT:
      const cta = cardCTAMapper({
        data: props.primaryCTA,
        options: {
          asLink: false,
        },
      })
      return {
        data: {
          media,
          hideImage,
          category: valueOrUndefined(props.eyebrow),
          title: valueOrUndefined(props.primaryTitle),
          subtitle: valueOrUndefined(props.secondaryTitle),
          secondaryTitle: valueOrUndefined(props.primarySubtitle),
          secondarySubtitle: valueOrUndefined(props.secondarySubtitle),
          portableTextDescription: props.descriptionOverride
            ? createPortableTextElement({ text: props.descriptionOverride })
            : undefined,
          ...cta,
          ...linkCTA,
        },
      }
    case CardTypes.ARTWORK:
      const inquiryModalProps = createInquireModalArtworkProps(data)
      const artworkOverrideCtas = {
        primaryCTA: cardCTAMapper(
          {
            data: props.primaryCTA,
            options: {
              asLink: false,
              modalProps: inquiryModalProps,
            },
          },
          CardTypes.PRODUCT
        ),
        secondaryCTA: cardCTAMapper(
          {
            data: props.secondaryCTA,
            options: {
              asLink: false,
              modalProps: inquiryModalProps,
            },
          },
          CardTypes.PRODUCT
        ),
      }
      return {
        data: {
          artistName: valueOrUndefined(props.artistName),
          portableTextArtworkTitle: props.artworkTitle
            ? createPortableTextElement({
                text: props.artworkTitle,
                customStyles: { normal: 'inline' },
                containerStyles: 'inline',
              })
            : undefined,
          artworkYear: valueOrUndefined(props.artworkYear),
          medium: valueOrUndefined(props.medium),
          portableTextDimensions: props.dimensions
            ? createPortableTextElement({
                text: props.dimensions,
                customStyles: { normal: 'text-black-60 !text-sm' },
              })
            : undefined,
          portableTextFramedDimensions: props.framedDimensions
            ? createPortableTextElement({
                text: props.framedDimensions,
                customStyles: { normal: 'text-black-60 !text-sm' },
              })
            : undefined,
          framed:
            props.framed === ArtworkFramedSchema.enum.NotApplicable
              ? ''
              : valueOrUndefined(props.framed),
          price: valueOrUndefined(props.price),
          currency: valueOrUndefined(props.currency),
          portableTextEdition: props.editionInformation
            ? createPortableTextElement({
                text: props.editionInformation,
                customStyles: { normal: 'text-black-60 !text-sm' },
              })
            : undefined,
          portableTextAdditionalInformation: props.additionalInformation
            ? createPortableTextElement({
                text: props.additionalInformation,
                customStyles: { normal: 'text-black-60 !text-sm' },
              })
            : undefined,

          media,
          hideImage,
          ...artworkOverrideCtas,
        },
      }
    case CardTypes.MEDIA:
      return {
        data: {
          media,
          hideImage,
        },
      }
    case CardTypes.LOCATION:
    default:
      throw new Error('Not implemented yet')
  }
}
