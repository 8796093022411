import type { ArtworkDataType } from '@/sanity/queries/artworks/artworkData'
import type { BookContentType } from '@/sanity/queries/components/content/bookContent'
import usePageStore from '@/store/pageStore'

type MapEcommerceData = (
  ecommObject: BookContentType | ArtworkDataType,
  extra?: {
    currency?: boolean
    item_list_id?: boolean
    item_list_name?: boolean
    items?: { item_list_id?: boolean; item_list_name?: boolean; price?: boolean }
  }
) => DataLayerEcommerceProps

const mapperByEcommType: Record<string, MapEcommerceData> = {
  artwork: (artwork, extra) => {
    const artworkData = artwork as ArtworkDataType
    const { title, hash, section } = usePageStore.getState()
    const ecommerce: DataLayerEcommerceProps = {
      value: artworkData.price || 0,
      items: [
        {
          affiliation: 'DZW',
          artwork_ecommerce: artworkData.product?.variants[0].store?.inventory?.isAvailable
            ? 'yes'
            : 'no',
          artwork_frame: artworkData.framed,
          artwork_price: artworkData.price?.toString() || '',
          artwork_size: artworkData.dimensions
            ? artworkData.dimensions[0]?.children[0]?.text || ''
            : '',
          artwork_status: artworkData.product?.variants[0].store?.inventory?.isAvailable
            ? 'available'
            : 'sold',
          item_brand: artwork.artists?.at(0)?.fullName || '',
          item_category: title,
          item_category2: section,
          item_category3: artworkData.artworkType,
          item_category4: artworkData.medium || '',
          item_category5: artworkData._createdAt,
          item_id: artworkData.inventoryId || '',
          item_name: artworkData.title,
          item_variant: artworkData.dateSelection?.year ?? '',
        },
      ],
    }

    if (extra?.item_list_id) ecommerce.item_list_id = hash
    if (extra?.item_list_name) ecommerce.item_list_name = title
    if (extra?.currency) ecommerce.currency = artworkData.currency || ''
    if (extra?.items?.item_list_id && ecommerce.items[0]) ecommerce.items[0].item_list_id = hash
    if (extra?.items?.item_list_name && ecommerce.items[0])
      ecommerce.items[0].item_list_name = title
    if (extra?.items?.price && ecommerce.items[0])
      ecommerce.items[0].price = artworkData.product?.variants[0].store?.price || 0

    return ecommerce
  },
  book: (book) => {
    const bookData = book as BookContentType
    const { title, hash } = usePageStore.getState()
    const ecommerce: BookEcommProps = {
      value: bookData.price || 0,
      // Required Post GoSC
      item_list_id: hash,
      item_list_name: title,
      items: [
        {
          affiliation: 'DZ Books',
          item_list_id: hash,
          item_brand: bookData.artists?.at(0)?.fullName || '',
          item_category: bookData?.publisher?.name || '',
          item_id: bookData.isbn || '',
          item_name: bookData.title,
          item_variant: bookData.yearSelection || '',
        },
      ],
    }

    return ecommerce
  },
}
export const mapEcommerceData: MapEcommerceData = (data, extra) => {
  const { _type } = data
  const ecommMapper = mapperByEcommType[_type]
  return ecommMapper(data, extra)
}
