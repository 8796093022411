import type { ColumnSpan } from '@zwirner/design-system'
import { DzColumn, DzGridColumns, MEDIA_ASPECT_RATIOS } from '@zwirner/design-system'
import Image from 'next/image'

import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { DzMedia } from '@/components/wrappers/DzMediaWrapper'
import { DzPortableText } from '@/components/wrappers/DzPortableTextWrapper'
import {
  type MediaBuilderSchemaType,
  MediaBuilderTypes,
} from '@/sanity/queries/components/builders/mediaBuilder'
import { type Cols } from '@/sanity/queries/components/dzImageMosaicProps'

export type Props = {
  elements: {
    media: MediaBuilderSchemaType
    mediaControls: {
      width: Cols
      offset: Cols
    }
  }[]
}

export const DzImageMosaic = ({ elements }: Props) => (
  <div className="flex flex-col gap-5 md:block">
    {elements.map((element, index) => {
      const { media } = dzMediaMapper({
        data: { photos: [element.media] },
        ImgElement: Image,
        options: {
          aspectRatio: MEDIA_ASPECT_RATIOS['AUTO'],
        },
      })
      const start = parseInt(element.mediaControls.offset) || 1
      const span = parseInt(element.mediaControls.width) || 10

      if (start + span > 13) return null

      return (
        <DzGridColumns key={index}>
          <DzColumn
            start={start as ColumnSpan}
            span={span as ColumnSpan}
            className="md:p-[3.75rem]"
          >
            <DzMedia {...media} />
            {element.media.type === MediaBuilderTypes.IMAGE && !!element.media.caption ? (
              <DzPortableText
                portableProps={{ value: element.media.caption }}
                customStyles={{
                  normal: 'mt-2.5 text-black-60 text-xs md:text-xs',
                }}
              />
            ) : null}
          </DzColumn>
        </DzGridColumns>
      )
    })}
  </div>
)
