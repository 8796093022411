import type { DzCarouselCardSize } from '@zwirner/design-system'
import { carouselSizeToCardSize, DzCarousel, DzColumn } from '@zwirner/design-system'

import { DzCard } from '@/components/pageBuilder/DzCard/DzCard'
import type { DzCarouselPropsDataType } from '@/sanity/queries/components/dzCarouselProps'

import { CarouselContainerContext } from '../context'
import { DzMedia } from '../DzMedia/DzMedia'

type CarouselMoleculeProps = {
  data: any
  componentProps?: DzCarouselPropsDataType
  parentId: string
}

const componentsIndex = {
  dzCard: DzCard,
  dzMedia: DzMedia,
} as const
type CarouselContentNodes = (typeof componentsIndex)[keyof typeof componentsIndex]

const checkIfDzMedia = (
  ComponentModule: CarouselContentNodes,
  _type: keyof typeof componentsIndex
): ComponentModule is (typeof componentsIndex)['dzMedia'] => _type === 'dzMedia'

const DEFAULT_CAROUSEL_SIZE = 'S'

export const CarouselMolecule = ({ componentProps, parentId }: CarouselMoleculeProps) => {
  const { size, dzCarousel = [], artworkFilters, bookFilters } = componentProps ?? {}
  const cardSize = size ?? DEFAULT_CAROUSEL_SIZE

  if (!dzCarousel) return null
  return (
    <DzColumn className="mb-12" span={12}>
      <CarouselContainerContext.Provider value={{ isInCarousel: true }}>
        <DzCarousel size={cardSize}>
          {dzCarousel.map((component, idx) => {
            const { _type, props, content = [] } = component
            const ComponentModule = componentsIndex[_type]
            const multipleContent = false

            const innerNotContentDependant = checkIfDzMedia(ComponentModule, _type)
              ? ComponentModule.notContentDependant
              : false
            const componentContent = multipleContent ? content : content?.[0]
            if (!ComponentModule) {
              console.warn('PAGE BUILDER::: Not supported component for the Carousel:', _type)
              return null
            }
            if (!componentContent && !innerNotContentDependant) {
              console.warn(
                'PAGE BUILDER::: Please add content types to this component inside of the Carousel:',
                props?.title ?? _type
              )
              return null
            }

            return (
              <div id={`${parentId}-${_type}-${idx}`} key={`${_type}-${idx}`}>
                <ComponentModule
                  data={componentContent}
                  componentProps={{
                    ...((props ?? {}) as any),
                    cardSize: carouselSizeToCardSize[cardSize as DzCarouselCardSize],
                    artworkFilters,
                    bookFilters,
                  }}
                  parentId={parentId}
                />
              </div>
            )
          })}
        </DzCarousel>
      </CarouselContainerContext.Provider>
    </DzColumn>
  )
}
CarouselMolecule.notContentDependant = true
CarouselMolecule.multipleContentTypes = true
export default CarouselMolecule
