import { DzHero as DzHeroBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzHeroProps = ComponentProps<typeof DzHeroBasic>
type Props = Omit<DzHeroProps, 'LinkElement'>

export const DzHero = (props: Props) => {
  const dzHeroProps = { ...props, LinkElement: Link } as DzHeroProps
  return <DzHeroBasic {...dzHeroProps} />
}

DzHero.displayName = 'DzHeroWrapper'
