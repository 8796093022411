import type { CardMediaData } from '@zwirner/design-system'
import { CardSizes, CardTypes, MEDIA_ASPECT_RATIOS } from '@zwirner/design-system'
import Image from 'next/image'

import { INSTALLATION_VIEWS } from '@/common/constants/commonCopies'
import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { createPortableTextElement } from '@/common/utilsMappers/safe'
import type { DzMediaSchemaProps } from '@/sanity/types'

export const dzMediaOverrides = (
  props: DzMediaSchemaProps,
  mediaType?: string | null
): { type: CardTypes.MEDIA; data: Omit<CardMediaData, 'LinkElement'> } => {
  const { media: mediaProps } = props ?? {}
  const { media, extras } = dzMediaMapper({ data: mediaProps, ImgElement: Image })

  const hasInstallationViews = mediaType === INSTALLATION_VIEWS

  return {
    data: {
      size: CardSizes['12col'],
      media: {
        ...media,
        ...(hasInstallationViews && { aspectRatio: MEDIA_ASPECT_RATIOS.AUTO }),
      },
      portableTextDescription: createPortableTextElement({
        text: extras?.caption ?? '',
        customStyles: { normal: 'text-black-60 mt-2.5 md:text-xs text-xs' },
      }),
    },
    type: CardTypes.MEDIA,
  }
}

export const contentTypesMapper: any = {}
