import { DzComplexGrid as DzComplexGridBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzComplexGridProps = ComponentProps<typeof DzComplexGridBasic>
type Props = Omit<DzComplexGridProps, 'LinkElement'>

export const DzComplexGrid = (props: Props) => {
  const dzComplexGridProps = { ...props, LinkElement: Link } as DzComplexGridProps
  return <DzComplexGridBasic {...dzComplexGridProps} />
}

DzComplexGrid.displayName = 'DzComplexGridWrapper'
