import { isEqual } from 'lodash-es'
import { useRouter } from 'next/router'
import { LiveQueryProvider, useLiveQuery } from 'next-sanity/preview'
import { createContext, memo, useDeferredValue, useEffect, useMemo, useState } from 'react'

import Layout from '@/common/components/layout'
import { DefaultSEOComponent, SEOComponent } from '@/common/components/seo/seo'
import { REVIEW_LINK_TOKEN_COOKIE } from '@/common/constants/api'
import { ErrorBoundary } from '@/components/wrappers/ErrorBoundary'
import { getClient } from '@/sanity/client'
import type { FooterDataSchemaType } from '@/sanity/queries/common/footerData'
import { footerData } from '@/sanity/queries/common/footerData'
import { generalSettings } from '@/sanity/queries/common/generalSettings'
import { headerData } from '@/sanity/queries/common/headerData'
import type { PageSEOFieldsExtendedType } from '@/sanity/queries/components/seo/pageSEOFields'
import type { LayoutProps } from '@/sanity/services/common/getCommonData'
import { getCookie } from '@/utils/cookies/getCookie'

import { PreviewBanner } from '../sharePreview'

export type PreviewStateType = {
  isShare: boolean
  tkn: string | undefined
}
export const PreviewContext = createContext<PreviewStateType>({
  isShare: false,
  tkn: undefined,
})

export type PreviewPageProps = {
  data: any
  token: string
  seo?: PageSEOFieldsExtendedType
  query: string
  params?: any
  Container: any
  layoutProps: LayoutProps
  isShare?: boolean
}

const ContainerData = ({
  data,
  seo: componentSEO,
  query,
  params = {},
  Container,
  layoutProps,
}: PreviewPageProps) => {
  const { layoutData, globalSEO } = layoutProps
  const layoutDataDeferred = useDeferredValue(layoutData)
  const globalSEODeferred = useDeferredValue(globalSEO)
  const [liveData] = useLiveQuery(data, query, params, {
    isEqual,
  }) as any
  const [headerDataRes] = useLiveQuery([layoutDataDeferred.headerData], headerData, undefined, {
    isEqual,
  }) as any
  const [footerDataRes] = useLiveQuery([layoutDataDeferred.footerData], footerData, undefined, {
    isEqual,
  }) as any
  const [generalSettingsRes] = useLiveQuery(
    { globalSEO: [globalSEODeferred] },
    generalSettings,
    undefined,
    {
      isEqual,
    }
  ) as any

  const generalSettingsDeferred = useDeferredValue(generalSettingsRes)

  const liveDataDeferred = useDeferredValue(liveData)
  const headerDataDeferred = useDeferredValue(headerDataRes)
  const footerDataDeferred = useDeferredValue(footerDataRes)
  const generalSettingsResDeferred = useDeferredValue(generalSettingsRes)
  const MemoizedContainer = useMemo(
    () => () => (Container ? <Container data={liveDataDeferred} /> : null),
    [liveDataDeferred, Container]
  )

  if (
    !headerDataDeferred ||
    !footerDataDeferred ||
    !generalSettingsResDeferred ||
    !liveDataDeferred
  )
    return null

  const layoutDataLiveDeferred = {
    headerData: headerDataDeferred.at(0),
    footerData: footerDataDeferred.at(0) as FooterDataSchemaType[number],
  }

  return (
    <>
      <DefaultSEOComponent globalSEO={generalSettingsDeferred.globalSEO.at(0)} />
      <ErrorBoundary>
        <Layout
          layoutData={layoutDataLiveDeferred}
          pageType={liveDataDeferred?._type}
          draftMode={true}
        >
          {componentSEO ? <SEOComponent data={componentSEO} /> : null}
          <MemoizedContainer />
        </Layout>
      </ErrorBoundary>
    </>
  )
}
const ContainerDataMemo = memo(ContainerData, isEqual)

const PreviewPage = (props: PreviewPageProps) => {
  const { client } = getClient({
    draftMode: true,
    draftViewToken: props.token,
    originName: 'preview-PreviewPage',
    filePath: __filename,
  })
  const [previewTkn, setPrevTkn] = useState()
  const { asPath } = useRouter()
  const isDZ30Exhibition = asPath.includes('exhibitions/2024/david-zwirner-30-years')
  const refreshInterval = isDZ30Exhibition ? 60 * 1000 * 45 : 10 * 1000 // 2 minutes for DZ30, 10 seconds for everything else

  useEffect(() => {
    const prevToken = getCookie(REVIEW_LINK_TOKEN_COOKIE)
    setPrevTkn(prevToken)
  }, [])

  return (
    <PreviewContext.Provider value={{ isShare: !!props.isShare, tkn: previewTkn }}>
      <LiveQueryProvider client={client} logger={console} refreshInterval={refreshInterval}>
        <PreviewBanner
          docType={props.data?._type}
          status={props.data?.status}
          isShare={props.isShare}
        >
          <ContainerDataMemo {...props} />
        </PreviewBanner>
      </LiveQueryProvider>
    </PreviewContext.Provider>
  )
}

const PreviewPageMemo = memo(PreviewPage, isEqual)

export default PreviewPageMemo
