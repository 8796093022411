import { DzComplexGrid } from '@/components/wrappers/DzComplexGridWrapper'

import { cardsMapper } from './dzComplexGridMapper'

type Props = {
  data: any
  componentProps?: any
  parentId: string
}

export const ComplexGridMolecule = ({ componentProps, parentId }: Props) => {
  const gridData = cardsMapper(componentProps)

  return <DzComplexGrid {...gridData} parentId={parentId} />
}
ComplexGridMolecule.notContentDependant = true
ComplexGridMolecule.multipleContentTypes = true
export default ComplexGridMolecule
