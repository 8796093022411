import { DzHero as DzHeroMolecule } from '@/components/wrappers/DzHeroWrapper'
import type { DzHeroSchemaProps } from '@/sanity/queries/components/dzHeroProps'
import { deepMerge } from '@/utils/object/deepMerge'

import { contentTypesMapper, dzHeroOverrides } from './heroMapper'

type DzHeroProps = {
  data: any
  componentProps?: DzHeroSchemaProps
  parentId: string
}

export const DzHero = ({ data, componentProps }: DzHeroProps) => {
  const heroSlides = data
    ?.map((slide: any) => {
      const { _type } = slide ?? {}
      const mappedData = contentTypesMapper[_type] ? contentTypesMapper[_type](slide) : null
      if (!mappedData) return null
      const overrideData = componentProps ? dzHeroOverrides(componentProps) : {}
      return deepMerge(mappedData, overrideData, 2)
    })
    .filter(Boolean)
  // no supported records by type found
  if (!heroSlides?.length) return null

  return <DzHeroMolecule items={heroSlides} />
}

DzHero.multipleContentTypes = true
export default DzHero
