import { DzInterstitial as DzInterstitialBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzInterstitialProps = ComponentProps<typeof DzInterstitialBasic>
type Props = Omit<DzInterstitialProps, 'LinkElement'>

export const DzInterstitial = (props: Props) => {
  const dzInterstitialProps = { ...props, LinkElement: Link } as DzInterstitialProps
  return <DzInterstitialBasic {...dzInterstitialProps} />
}

DzInterstitial.displayName = 'DzInterstitialWrapper'
