import { DzColumn, DzGridColumns } from '@zwirner/design-system'

import { DzCard } from '@/components/pageBuilder/DzCard/DzCard'
import { ComplexGridMolecule } from '@/components/pageBuilder/DzComplexGrid/DzComplexGrid'
import { getRows } from '@/components/pageBuilder/DzComplexGrid/dzComplexGridMapper'
import cn from '@/utils/cn'

import { GridContainerContext, GridTypes } from '../context'
import { DzMedia } from '../DzMedia/DzMedia'

type GridMoleculeProps = {
  data: any
  componentProps?: any & {
    columnsWrapperClassName?: string
    columnsClassName?: string
    containerClassName?: string
  }
  parentId: string
}

const componentsIndex: any = {
  dzCard: DzCard,
  dzMedia: DzMedia,
}

const DEFAULT_ITEMS_PER_ROW = 1

export const GridMolecule = ({ componentProps, data, parentId }: GridMoleculeProps) => {
  const {
    itemsPerRow,
    wrap = false,
    grid = [],
    displayNumberOfItems = false,
    displayGridSlider = false,
    columnsWrapperClassName,
    columnsClassName,
    containerClassName,
    customCardSize,
  } = componentProps
  const numberOfItemsPerRow = itemsPerRow ?? DEFAULT_ITEMS_PER_ROW
  const getColSpan = getRows(numberOfItemsPerRow ?? 0)
  const isComplexGrid = displayNumberOfItems || displayGridSlider
  const { artworkFilters, bookFilters } = componentProps
  if (isComplexGrid) {
    return (
      <DzColumn className="mb-12" span={12}>
        <GridContainerContext.Provider value={{ isInGrid: true, type: GridTypes.COMPLEX }}>
          <ComplexGridMolecule data={data} componentProps={componentProps} parentId={parentId} />
        </GridContainerContext.Provider>
      </DzColumn>
    )
  }

  return (
    <DzColumn className={cn('mb-12', columnsWrapperClassName)} span={12}>
      <DzGridColumns
        className={cn('h-full w-full gap-y-[2.5rem] md:gap-y-[3.75rem]', columnsClassName)}
      >
        {(grid as Array<unknown> | undefined)?.map((component: any, idx) => {
          const { _type, props, content = [] } = component
          const ComponentModule = componentsIndex[_type]
          const multipleContent = ComponentModule?.multipleContentTypes ?? false
          const innerNotContentDependant = ComponentModule?.notContentDependant ?? false
          const componentContent = multipleContent ? content : content?.[0]
          if (!ComponentModule) {
            console.warn('PAGE BUILDER::: Not supported component for the grid:', _type)
            return null
          }
          const isCustomCard = props?.customCardEnabled
          if (!componentContent && !innerNotContentDependant && !isCustomCard) {
            console.warn(
              'PAGE BUILDER::: Please add content types to this component inside of the grid:',
              props?.title ?? _type
            )
            return null
          }
          return (
            <DzColumn
              className={cn('mb-5', containerClassName)}
              key={`grid-section-${idx}`}
              span={getColSpan}
              wrap={wrap}
            >
              <GridContainerContext.Provider value={{ isInGrid: true, type: GridTypes.SIMPLE }}>
                <div id={`${parentId}-${_type}-${idx}`} key={`${_type}-${idx}`}>
                  <ComponentModule
                    data={componentContent}
                    componentProps={{
                      ...props,
                      cardSize: customCardSize || `${getColSpan}col`,
                      artworkFilters,
                      bookFilters,
                    }}
                    parentId={parentId}
                  />
                </div>
              </GridContainerContext.Provider>
            </DzColumn>
          )
        })}
      </DzGridColumns>
    </DzColumn>
  )
}
GridMolecule.notContentDependant = true
GridMolecule.multipleContentTypes = true
export default GridMolecule
