import { DzTitleMolecule as DzTitleMoleculeBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzTitleMoleculeProps = ComponentProps<typeof DzTitleMoleculeBasic>
type Props = Omit<DzTitleMoleculeProps, 'LinkElement'>

export const DzTitleMolecule = (props: Props) => {
  const dzTitleMoleculeProps = { ...props, LinkElement: Link } as DzTitleMoleculeProps
  return <DzTitleMoleculeBasic {...dzTitleMoleculeProps} />
}

DzTitleMolecule.displayName = 'DzTitleMoleculeWrapper'
