import { DzEditorial as DzEditorialBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzEditorialProps = ComponentProps<typeof DzEditorialBasic>
type Props = Omit<DzEditorialProps, 'LinkElement'>

export const DzEditorial = (props: Props) => {
  const dzEditorialProps = { ...props, LinkElement: Link } as DzEditorialProps
  return <DzEditorialBasic {...dzEditorialProps} />
}

DzEditorial.displayName = 'DzEditorialWrapper'
