import { MEDIA_ASPECT_RATIOS, MediaIcons } from '@zwirner/design-system'
import Image from 'next/image'

import { FAIR, FAIRS_URL, LEARN_MORE, LISTEN_NOW } from '@/common/constants/commonCopies'
import {
  ctaMapperByMoleculeType,
  MappedCTATypes,
  simpleMoleculeMapper,
} from '@/common/utilsMappers/cta.mapper'
import { mapSingleDateFormat, mapStatus } from '@/common/utilsMappers/date.mapper'
import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { createPortableTextElement, safeText } from '@/common/utilsMappers/safe'
import { cardContentArticle } from '@/components/pageBuilder/utils/commonMappers'
import { MediaBuilderTypes } from '@/sanity/queries/components/builders/mediaBuilder'
import type { FieldsPerTypeSchemaType } from '@/sanity/queries/components/componentTypesData'
import type { PageBuilderComponentsDataSchemaType } from '@/sanity/queries/page/pageCommonQueries/pageBuilderComponentsData'
import type { DzSplitTypeExtendedProps } from '@/sanity/types'
import { capitalizeFirstLetter } from '@/utils/string/capitalizeFirstLetter'

import { PageBuilderComponents } from '../types'
import { exhibitionMapper } from './common'

const splitSimpleCTAMapper = simpleMoleculeMapper(PageBuilderComponents.dzSplit)
const splitCTAMapper = ctaMapperByMoleculeType(PageBuilderComponents.dzSplit)

export const showSplitSection = (data: PageBuilderComponentsDataSchemaType) => {
  if (data?._type !== 'dzSplit') return false
  const { content, props } = data ?? {}
  const { titleOverride, subtitleOverride } = props ?? {}
  const hasOverridesField = titleOverride || subtitleOverride
  return hasOverridesField || !!content
}

export const dzSplitOverrides = (props: DzSplitTypeExtendedProps) => {
  const { titleOverride, splitType, reverse, subtitleOverride } = props

  const cta = ctaMapperByMoleculeType(PageBuilderComponents.dzSplit)({
    data: props.primaryCTA,
    options: { asLink: false },
  })

  const { media } = dzMediaMapper({
    data: props.media,
    ImgElement: Image,
  })

  return {
    type: splitType,
    reverse,
    data: {
      ...(media?.imgProps?.src ? { media } : {}),
      ...(titleOverride ? { title: titleOverride } : {}),
      ...(subtitleOverride ? { description: subtitleOverride } : {}),
      ...cta,
    },
  }
}

export const splitMappers: any = {
  artist: (data: any) => {
    const { splitType, reverse, birthdate, picture, fullName, description, summary } = data

    const { media } = dzMediaMapper({
      data: picture,
      ImgElement: Image,
    })

    return {
      type: splitType,
      reverse,
      data: {
        media,
        category: 'Artist',
        title: fullName,
        subtitle: birthdate,
        secondaryTitle: summary,
        description,
      },
    }
  },
  article: (data: FieldsPerTypeSchemaType, props: any) => {
    if (data._type !== 'article') throw new Error('Invalid data type')
    const cardProps = cardContentArticle({
      data: {
        ...data,
        title: data.title || '',
      },
      props,
    })
    const { splitType, reverse } = props ?? {}

    return {
      type: splitType,
      reverse,
      data: cardProps,
    }
  },
  artwork: (data: any) => {
    const {
      splitType,
      reverse,
      artists,
      availability,
      dimensions,
      edition,
      medium,
      title,
      dateSelection,
    } = data
    const [mainArtist] = artists ?? []

    const { media } = dzMediaMapper({
      data,
      ImgElement: Image,
    })

    return {
      type: splitType,
      reverse,
      data: {
        media,
        category: availability,
        title,
        subtitle: dateSelection?.year,
        secondarySubtitle: `${mainArtist?.fullName}`,
        description: `${dimensions} ${edition} ${medium}`,
      },
    }
  },

  exhibitionPage: (data: FieldsPerTypeSchemaType, props: DzSplitTypeExtendedProps) => {
    if (data._type !== 'exhibitionPage') throw new Error('Invalid data type')
    return exhibitionMapper({ data, props })
  },
  onlineExhibitionPage: (data: FieldsPerTypeSchemaType, props: DzSplitTypeExtendedProps) => {
    if (data._type !== 'onlineExhibitionPage') throw new Error('Invalid data type')
    return exhibitionMapper({ data, props })
  },
  exceptionalWork: (data: FieldsPerTypeSchemaType, props: DzSplitTypeExtendedProps) => {
    if (data._type !== 'exceptionalWork') throw new Error('Invalid data type')
    return exhibitionMapper({ data, props })
  },
  fairPage: (data: FieldsPerTypeSchemaType, props: DzSplitTypeExtendedProps) => {
    if (data._type !== 'fairPage') throw new Error('Invalid data type')

    const { title, summary, location, slug, heroMedia, cardViewMedia, eyebrow } = data
    const { splitType, reverse } = props ?? {}
    const heroMediaSource =
      Object.keys(heroMedia ?? {}).length > 0 && heroMedia?.type !== MediaBuilderTypes.UNSET
        ? heroMedia
        : null
    const cardViewMediaSource =
      Object.keys(cardViewMedia ?? {}).length > 0 && cardViewMedia?.type !== MediaBuilderTypes.UNSET
        ? cardViewMedia
        : null
    const { media } = dzMediaMapper({
      data: cardViewMediaSource ?? heroMediaSource ?? data,
      ImgElement: Image,
    })

    const link = (data.externalUrlToggle ? data.externalUrl : slug?.current) ?? FAIRS_URL
    const linkCTAText = data.externalUrlToggle
      ? `${LEARN_MORE} at ${new URL(data.externalUrl || FAIRS_URL).hostname.replace('www.', '')}`
      : LEARN_MORE

    const descriptionText = safeText({ key: 'description', text: summary || '' })

    const cta = {
      linkCTA: {
        text: linkCTAText,
        url: link,
      },
    }

    const { status } = mapStatus(data)

    const subtitle =
      data.boothOrStand && data.boothOrStandNumber
        ? capitalizeFirstLetter(`${data.boothOrStand}: ${data.boothOrStandNumber}`)
        : ''

    return {
      type: splitType,
      reverse,
      data: {
        media,
        category: eyebrow ?? FAIR,
        title,
        subtitle,
        ...descriptionText,
        secondaryTitle: location?.name,
        secondarySubtitle: status,
        ...cta,
      },
    }
  },
  podcast: (data: FieldsPerTypeSchemaType, props: DzSplitTypeExtendedProps) => {
    if (data._type !== 'podcast') throw new Error('Invalid data type')
    const { title, eyebrow, subtitle, dateSelection, description, itunesUrl, image, cta } = data
    const { splitType, reverse } = props ?? {}
    const date = mapSingleDateFormat(dateSelection)

    const { media } = dzMediaMapper({
      data: image,
      ImgElement: Image,
      options: {
        imgIcon: MediaIcons.Microphone,
        aspectRatio: MEDIA_ASPECT_RATIOS.AUTO,
      },
    })

    const mediaToRender = media?.imgProps?.src ? media : null

    const ctaOfPodcast = splitCTAMapper({ data: cta, options: { asLink: true } })
    const ctaOverrides = splitSimpleCTAMapper({
      type: MappedCTATypes.LINK,
      supported: true,
      text: LISTEN_NOW,
      url: itunesUrl ? itunesUrl : undefined,
      openNewTab: true,
    })

    return {
      type: splitType,
      reverse,
      data: {
        media: mediaToRender,
        category: eyebrow,
        title,
        subtitle,
        portableTextDescription: description
          ? createPortableTextElement({
              text: description,
            })
          : undefined,
        secondarySubtitle: date,
        ...ctaOverrides,
        ...ctaOfPodcast,
      },
    }
  },
}
