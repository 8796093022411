import { DzSplit as DzSplitBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzSplitProps = ComponentProps<typeof DzSplitBasic>
type Props = Omit<DzSplitProps, 'LinkElement'>

export const DzSplit = (props: Props) => {
  const dzSplitProps = { ...props, LinkElement: Link } as DzSplitProps
  return <DzSplitBasic {...dzSplitProps} />
}

DzSplit.displayName = 'DzSplitWrapper'
