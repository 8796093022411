import { MEDIA_TYPES } from '@zwirner/design-system'
import Image from 'next/image'

import { SIGN_UP } from '@/common/constants/commonCopies'
import { CTA_CLICK_SOURCE, ctaMapperByMoleculeType } from '@/common/utilsMappers/cta.mapper'
import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { safeText } from '@/common/utilsMappers/safe'
import { builder } from '@/sanity/imageBuilder'
import type { MediaBuilderImageType } from '@/sanity/queries/components/builders/mediaBuilder'
import type { PageBuilderComponentsDataSchemaType } from '@/sanity/queries/page/pageCommonQueries/pageBuilderComponentsData'
import type { DzInterstitialTypeProps } from '@/sanity/types'

import { PageBuilderComponents } from '../types'

const interstitialCTAMapper = ctaMapperByMoleculeType(PageBuilderComponents.dzInterstitial)

export const showInterstitialSection = (data: PageBuilderComponentsDataSchemaType) => {
  if (data?._type !== 'dzInterstitial') return false
  const { props } = data ?? {}
  const { cta, image } = props ?? {}
  const { image: innerImg } = (image as MediaBuilderImageType) ?? {}
  const { text, action } = cta ?? {}
  const hasImgAttached = innerImg?.url
  const hasCTAText = !!(text && action)
  return hasCTAText || hasImgAttached
}

export const dzInterstitialOverrides = (props: DzInterstitialTypeProps) => {
  if (!props) return null
  const { title, subtitle, eyebrow, mode, cta, image, tags, registeredInterest } = props ?? {}
  const { media, hideMedia } = dzMediaMapper({
    data: image,
    ImgElement: Image,
    extraImgProps: {
      sizes: '100vw',
      priority: true,
    },
  })
  const ctasOverrides = interstitialCTAMapper({
    data: cta,
    options: {
      asLink: false,
      source: CTA_CLICK_SOURCE.INTERSTITIAL,
      modalProps: {
        method: 'interstitial',
        registeredInterest,
        tags,
      },
    },
  })
  const categoryText = safeText({ key: 'category', text: eyebrow })

  const descriptionTextCustomStyles = {
    normal: mode === 'Light' ? 'text-white-100' : 'text-black-100',
  }
  const descriptionText = safeText({
    key: 'description',
    text: subtitle,
    customStyles: descriptionTextCustomStyles,
  })
  return {
    data: {
      split: false,
      title,
      ...descriptionText,
      ...categoryText,
      mode: mode || 'Dark',
      ...(hideMedia ? {} : { media }),
      ...ctasOverrides,
      customClass: '-mx-5',
    },
  }
}

export const interstitialMap = {
  artist: (data: any) => {
    const { split, birthdate, fullName, picture, description } = data ?? {}
    const { asset, alt } = picture ?? {}
    const imgSrc = asset ? builder.image(asset).url() : ''
    return {
      data: {
        split,
        category: birthdate,
        title: fullName,
        description,
        primaryCta: {
          text: SIGN_UP,
        },
        media: {
          url: '/',
          type: MEDIA_TYPES.IMAGE,
          imgProps: {
            src: imgSrc,
            alt,
          },
        },
      },
    }
  },
  artwork: (data: any) => {
    const { split = false, photos, availability, dimensions, edition, medium, title } = data ?? {}
    const [mainPicture] = photos ?? []
    const { asset, alt } = mainPicture ?? {}
    const imgSrc = asset ? builder.image(asset).url() : ''
    return {
      data: {
        split,
        category: availability,
        title,
        description: `${medium} // ${dimensions} // ${edition}`,
        primaryCta: {
          text: SIGN_UP,
        },
        media: {
          url: '/',
          type: MEDIA_TYPES.IMAGE,
          imgProps: {
            src: imgSrc,
            alt,
          },
        },
      },
    }
  },
  exhibition: (data: any) => {
    const { split = false, events, title, summary, description } = data ?? {}
    const [event] = events ?? []
    const { photos } = event ?? {}
    const [mainPicture] = photos ?? []
    const { asset, alt } = mainPicture ?? {}
    const imgSrc = asset ? builder.image(asset).url() : ''
    return {
      data: {
        split,
        category: summary,
        title,
        description,
        primaryCta: {
          text: SIGN_UP,
        },
        media: {
          url: '/',
          type: MEDIA_TYPES.IMAGE,
          imgProps: {
            src: imgSrc,
            alt,
          },
        },
      },
    }
  },
}
