import { CardTypes, CardViewport, MEDIA_ASPECT_RATIOS, MediaIcons } from '@zwirner/design-system'
import Image from 'next/image'

import {
  ECOMM_COLLECT_CARD_CTA,
  ECOMM_COLLECT_CARD_EYEBROW,
  LEARN_MORE,
  LISTEN_NOW,
} from '@/common/constants/commonCopies'
import {
  gtmProductListingItemClickedEvent,
  gtmProductListingViewedEvent,
} from '@/common/utils/gtm/gtmProductEvent'
import {
  ctaMapperByMoleculeType,
  MappedCTATypes,
  simpleMoleculeMapper,
} from '@/common/utilsMappers/cta.mapper'
import { mapSingleDateFormat } from '@/common/utilsMappers/date.mapper'
import { dzMediaMapper, getSizesByCardSize } from '@/common/utilsMappers/image.mapper'
import { createPortableTextElement, safeText } from '@/common/utilsMappers/safe'
// TODO: extract utils to a general mapper for availability
import { parseAvailability } from '@/components/containers/home/utils'
import { createInquireModalArtworkProps } from '@/components/hooks/useOpenInquiryDispatch'
import { exhibitionMapper, fairMapper } from '@/components/pageBuilder/DzCard/common'
import { cardContentArticle } from '@/components/pageBuilder/utils/commonMappers'
import type { FieldsPerTypeSchemaType } from '@/sanity/queries/components/componentTypesData'
import type { ArtworkContentType } from '@/sanity/queries/components/content/artworkContent'
import { ArtworkFramedSchema } from '@/sanity/queries/components/content/artworkContent'
import type { BookContentType } from '@/sanity/queries/components/content/bookContent'
import type { EcommArtistBooksCardContentType } from '@/sanity/queries/components/content/ecommArtistBookContent'
import type { EcommCollectCardContentType } from '@/sanity/queries/components/content/ecommCollectContent'
import type { DzCardExtendedProps } from '@/sanity/types'
import { CTAActionTypes } from '@/sanity/types'

import { PageBuilderComponents } from '../types'
import { bookMapper } from './bookCardMapper'

const ARTWORK_CARD_TITLE_CHAR_LIMIT = 300
const cardSimpleCTAMapper = simpleMoleculeMapper(PageBuilderComponents.dzCard)
const cardCTAMapper = ctaMapperByMoleculeType(PageBuilderComponents.dzCard)

export type ARTWORK_BG_COLOR_NAMES = 'transparent' | 'lightGrey' | 'darkGrey'

export const ARTWORK_BG_COLORS_TO_TW_VALUES = {
  transparent: '!bg-transparent',
  lightGrey: '!bg-[#f7f7f7]',
  darkGrey: '!bg-black-20',
}

type ContentTypesMapper = Record<
  string,
  (data: any, props: DzCardExtendedProps) => { type: CardTypes; data: any }
>

export const contentTypesMapper: ContentTypesMapper = {
  article: (data: FieldsPerTypeSchemaType, props: DzCardExtendedProps) => {
    if (data._type !== 'article') throw new Error('Invalid data type')
    const { cardSize } = props ?? {}
    const cardProps = cardContentArticle({
      data: {
        ...data,
        title: data.title || '',
      },
      props,
    })
    return {
      type: CardTypes.CONTENT,
      data: {
        size: cardSize,
        ...cardProps,
      },
    }
  },
  artist: (data: FieldsPerTypeSchemaType, props: DzCardExtendedProps) => {
    if (data._type !== 'artist') throw new Error('Invalid data type')
    const { media, hideImage } = dzMediaMapper({
      data: { image: data.biographyPicture },
      ImgElement: Image,
      extraImgProps: {
        sizes: getSizesByCardSize(props?.cardSize),
      },
    })
    return {
      type: CardTypes.CONTENT,
      data: {
        media,
        hideImage,
        title: data?.fullName,
        size: props?.cardSize,
      },
    }
  },
  artistPage: (data: FieldsPerTypeSchemaType, props: DzCardExtendedProps) => {
    if (data._type !== 'artistPage') throw new Error('Invalid data type')
    const { media } = dzMediaMapper({
      data: data.artist.biographyPicture,
      url: data.slug,
      ImgElement: Image,
      extraImgProps: {
        sizes: getSizesByCardSize(props?.cardSize),
      },
    })
    return {
      type: CardTypes.CONTENT,
      data: {
        media: {
          ...media,
          aspectRatio: MEDIA_ASPECT_RATIOS['16:9'],
        },
        title: data.title,
        size: props?.cardSize,
      },
    }
  },
  book: (data: BookContentType, props: DzCardExtendedProps) => {
    return bookMapper({ data, props })
  },
  artwork: (data: ArtworkContentType, props: DzCardExtendedProps) => {
    const {
      artists,
      backgroundColor,
      dimensions,
      displayCustomTitle,
      displayTitle,
      title,
      dateSelection,
      medium,
      editionInformation,
      price,
      currency,
      framed,
      slug,
      framedDimensions,
      displayDate,
    } = data ?? {}
    const imageBgColor = backgroundColor
      ? ARTWORK_BG_COLORS_TO_TW_VALUES[backgroundColor as ARTWORK_BG_COLOR_NAMES]
      : ''
    const { cardSize, enableOverrides, mediaOverride } = props ?? {}
    const [mainArtist] = artists ?? []
    const { fullName } = mainArtist ?? {}

    const displayFilters = props.artworkFilters

    const inquiryModalProps = createInquireModalArtworkProps(data)

    const ctas = [CTAActionTypes.LINK, CTAActionTypes.INQUIRE].some(
      (action) => action === data.artworkCTA?.primaryCTA?.action
    )
      ? {
          primaryCTA: cardCTAMapper(
            {
              data: data?.artworkCTA?.primaryCTA,
              options: { asLink: false, modalProps: inquiryModalProps },
            },
            CardTypes.PRODUCT
          ),
          secondaryCTA: cardCTAMapper(
            {
              data: data.artworkCTA?.secondaryCTA,
              options: { asLink: false, modalProps: inquiryModalProps },
            },
            CardTypes.PRODUCT
          ),
        }
      : {}

    const { media } = dzMediaMapper({
      data: (enableOverrides ? mediaOverride : null) ?? data?.photos?.[0],
      ImgElement: Image,
      extraImgProps: {
        sizes: getSizesByCardSize(cardSize),
      },
    })
    const artworkTitle = (title || '')
      .slice(0, ARTWORK_CARD_TITLE_CHAR_LIMIT)
      .concat(title?.length > ARTWORK_CARD_TITLE_CHAR_LIMIT ? '...' : '')
    const displayTitleText = displayCustomTitle
      ? safeText({
          key: 'artworkTitle',
          text: displayTitle,
          charLimit: ARTWORK_CARD_TITLE_CHAR_LIMIT,
          customStyles: { normal: 'inline' },
          containerStyles: 'inline',
        })
      : {}
    const editionInformationText = safeText({
      key: 'edition',
      text: editionInformation,
      customStyles: { normal: 'text-black-60 !text-sm' },
    })

    const dimensionText = safeText({
      key: 'dimensions',
      text: dimensions,
      customStyles: { normal: 'text-black-60 !text-sm' },
    })
    const mediumText = safeText({ key: 'medium', text: medium })
    const year = dateSelection?.year ?? ''
    const framedDimensionsText = safeText({
      key: 'framedDimensions',
      text: framedDimensions,
      customStyles: { normal: 'text-black-60 !text-sm' },
    })
    return {
      type: CardTypes.ARTWORK,
      displayFilters,
      data: {
        size: cardSize,
        media,
        artistName: fullName,
        artworkTitle,
        ...displayTitleText,
        artworkYear: displayDate || year,
        price,
        currency: currency || 'USD',
        framed: framed === ArtworkFramedSchema.enum.NotApplicable ? '' : framed,
        slug: slug?.current,
        ...editionInformationText,
        ...mediumText,
        ...dimensionText,
        ...framedDimensionsText,
        ...ctas,
      },
      onClickImage: () => {
        gtmProductListingItemClickedEvent(data as any)
      },
      onViewport: () => {
        gtmProductListingViewedEvent(data as any)
      },
      imageStyles: imageBgColor,
    }
  },
  exhibitionPage: (data: FieldsPerTypeSchemaType, props: DzCardExtendedProps) => {
    if (data._type !== 'exhibitionPage') throw new Error('Invalid data type')
    return exhibitionMapper({ data, props })
  },
  onlineExhibitionPage: (data: FieldsPerTypeSchemaType, props: DzCardExtendedProps) => {
    if (data._type !== 'onlineExhibitionPage') throw new Error('Invalid data type')
    return exhibitionMapper({ data, props })
  },
  exceptionalWork: (data: FieldsPerTypeSchemaType, props: DzCardExtendedProps) => {
    if (data._type !== 'exceptionalWork') throw new Error('Invalid data type')
    return exhibitionMapper({ data, props })
  },
  fairPage: (data: FieldsPerTypeSchemaType, props: DzCardExtendedProps) => {
    if (data._type !== 'fairPage') throw new Error('Invalid data type')
    return fairMapper({ data, props })
  },
  location: (data: FieldsPerTypeSchemaType, props: DzCardExtendedProps) => {
    if (data._type !== 'location') throw new Error('Invalid data type')
    const { name, address, hours } = data ?? {}
    const { cardSize } = props ?? {}
    const { addressLine, state, city, zipCode } = address ?? {}
    const { media, hideImage } = dzMediaMapper({
      data,
      ImgElement: Image,
    })
    const currentTime = new Date()
    const availability = parseAvailability(hours, currentTime)?.some(
      (time: { from: Date; to: Date }) =>
        currentTime.getTime() <= time.to.getTime() && currentTime.getTime() >= time.from.getTime()
    )
    return {
      type: CardTypes.CONTENT,
      data: {
        media,
        hideImage,
        title: name,
        secondaryTitle: (
          <>
            <span itemProp="streetAddress" property="streetAddress">
              {addressLine}
            </span>
            {'\n'}
            <span itemProp="addressRegion" property="addressRegion">
              {state}
            </span>
            ,{' '}
            <span itemProp="addressLocality" property="addressLocality">
              {city}
            </span>
            ,{' '}
            <span itemProp="postalCode" property="postalCode">
              {zipCode}
            </span>
          </>
        ),
        secondarySubtitle: availability ? 'Open' : 'Closed',

        size: cardSize,
      },
    }
  },
  podcast: (data: FieldsPerTypeSchemaType, props: DzCardExtendedProps) => {
    if (data._type !== 'podcast') throw new Error('Invalid data type')
    const { title, subtitle, dateSelection, description, itunesUrl, cta, image, eyebrow } = data
    const date = mapSingleDateFormat(dateSelection)

    const { cardSize, isInGrid } = props ?? {}
    const { media } = dzMediaMapper({
      data: image,
      ImgElement: Image,
      options: {
        imgIcon: MediaIcons.Microphone,
      },
    })
    const mediaToRender = media?.imgProps?.src ? media : null

    const ctaOfPodcast = cardCTAMapper({ data: cta, options: { asLink: true } })
    const ctaOverrides = cardSimpleCTAMapper({
      type: MappedCTATypes.LINK,
      supported: true,
      text: LISTEN_NOW,
      url: itunesUrl ? itunesUrl : undefined,
      openNewTab: true,
    })

    if (isInGrid) {
      return {
        viewport: CardViewport.Desktop,
        type: CardTypes.CONTENT,
        data: {
          category: eyebrow,
          media: mediaToRender,
          hideImage: !mediaToRender,
          title,
          size: cardSize,
          ...ctaOverrides,
          ...ctaOfPodcast,
        },
      }
    }
    return {
      viewport: CardViewport.Desktop,
      type: CardTypes.CONTENT,
      data: {
        category: eyebrow,
        media: mediaToRender,
        hideImage: !mediaToRender,
        title,
        subtitle,
        secondarySubtitle: date,
        portableTextDescription: description
          ? createPortableTextElement({
              text: description,
            })
          : undefined,
        size: cardSize,
        ...ctaOverrides,
        ...ctaOfPodcast,
      },
    }
  },
  series: (data: any, props: DzCardExtendedProps) => {
    const { title, image, slug, displayDate } = data ?? {}
    const { cardSize } = props ?? {}
    const { media, hideImage } = dzMediaMapper({
      data: image,
      ImgElement: Image,
      extraImgProps: {
        sizes: getSizesByCardSize(cardSize),
      },
    })
    const { current } = slug ?? {}

    return {
      viewport: CardViewport.Desktop,
      type: CardTypes.CONTENT,
      data: {
        media,
        hideImage,
        title: (
          <>
            <em>{title}</em> {displayDate ? `(${displayDate})` : ''}
          </>
        ),
        size: cardSize,
        linkCTA: {
          text: LEARN_MORE,
          url: current,
        },
      },
    }
  },
  collectionPage: (data: EcommCollectCardContentType, props: DzCardExtendedProps) => {
    const { title, description, cardViewMedia, slug } = data ?? {}
    const summaryText = safeText({ key: 'description', text: description || '' })
    const { cardSize } = props ?? {}
    const { media } = dzMediaMapper({
      data: cardViewMedia,
      ImgElement: Image,
      extraImgProps: {
        sizes: getSizesByCardSize(cardSize),
      },
    })
    const { current } = slug ?? {}
    return {
      viewport: CardViewport.Desktop,
      type: CardTypes.CONTENT,
      data: {
        slug: current,
        media,
        title,
        category: ECOMM_COLLECT_CARD_EYEBROW,
        size: cardSize,
        linkCTA: {
          text: ECOMM_COLLECT_CARD_CTA,
          url: current,
        },
        ...summaryText,
      },
    }
  },
  artistECommPage: (data: EcommArtistBooksCardContentType, props: DzCardExtendedProps) => {
    const { title, description, cardViewMedia, slug } = data ?? {}
    const summaryText = safeText({ key: 'description', text: description || '' })
    const { cardSize } = props ?? {}
    const { media } = dzMediaMapper({
      data: cardViewMedia,
      ImgElement: Image,
      extraImgProps: {
        sizes: getSizesByCardSize(cardSize),
      },
    })
    const { current } = slug ?? {}
    return {
      viewport: CardViewport.Desktop,
      type: CardTypes.CONTENT,
      data: {
        slug: current,
        media,
        title,
        size: cardSize,
        linkCTA: {
          text: ECOMM_COLLECT_CARD_CTA,
          url: current,
        },
        ...summaryText,
      },
    }
  },
}

export type ContentTypesMapperKeys = keyof typeof contentTypesMapper
