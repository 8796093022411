import { DzColumn } from '@zwirner/design-system'

import type { PageBuilderComponentsDataSchemaType } from '@/sanity/queries/page/pageCommonQueries/pageBuilderComponentsData'
import cn from '@/utils/cn'

import { ErrorBoundary } from '../wrappers/ErrorBoundary'
import { DzMoleculeTitle } from './components/DzMoleculeTitle'
import { PageBuilderContainer } from './components/PageBuilderContainer'
import { DzCard } from './DzCard/DzCard'
import { CarouselMolecule } from './DzCarousel/DzCarousel'
import { showCarouselSection } from './DzCarousel/dzCarouselMapper'
import { DzEditorial } from './DzEditorial/DzEditorial'
import { DzGalleryHero } from './DzGalleryHero/DzGalleryHero'
import { DzGalleryLanding } from './DzGalleryLanding/DzGalleryLanding'
import { DzHero } from './DzHero/DzHero'
import { DzImageMosaic } from './DzImageMosaic/DzImageMosaic'
import { DzInterstitial } from './DzInterstitial/DzInterstitial'
import { showInterstitialSection } from './DzInterstitial/interstitialMapper'
import { DzMedia } from './DzMedia/DzMedia'
import { DzSplit } from './DzSplit/DzSplit'
import { showSplitSection } from './DzSplit/splitMappers'
import { GridMolecule } from './GridMolecule'
import { showGridSection } from './GridMolecule/gridMapper'
import type { PageBuilderNames } from './types'

// Get all supported page builder molecules, OneUp is only a wrapper

const componentsIndex: Record<PageBuilderNames, any> = {
  dzHero: DzHero,
  dzCard: DzCard,
  dzEditorial: DzEditorial,
  dzSplit: DzSplit,
  dzInterstitial: DzInterstitial,
  dzMedia: DzMedia,
  dzCarousel: CarouselMolecule,
  grid: GridMolecule,
  dzGalleryHero: DzGalleryHero,
  dzImageMosaic: DzImageMosaic,
  dzLanding: DzGalleryLanding,
}

// TODO: merge validators and include custom content & non dependant on record
const componentsCheckCustomContent = {
  dzHero: () => true,
  // TODO: unify DzCardPropsDataSchema & DzCustomCardPropsSchema
  dzCard: (c: any) => c.props.customCardEnabled,
  dzEditorial: () => true,
  dzSplit: () => true,
  dzInterstitial: () => true,
  dzMedia: () => true,
  dzCarousel: () => true,
  grid: () => true,
  dzGalleryHero: () => true,
  dzImageMosaic: () => true,
  dzLanding: () => true,
} as const

const componentsValidators = {
  dzHero: () => true,
  dzCard: () => true,
  dzEditorial: () => true,
  dzSplit: showSplitSection,
  dzInterstitial: showInterstitialSection,
  dzMedia: () => true,
  dzCarousel: showCarouselSection,
  grid: showGridSection,
  dzGalleryHero: () => true,
  dzImageMosaic: () => true,
  dzLanding: () => true,
} as const

type PageBuilderProps = {
  id?: string
  components: PageBuilderComponentsDataSchemaType[]
  innerSection?: boolean
  containerClassName?: string
  className?: string
}

export const PageBuilder = ({
  id,
  components = [],
  innerSection = false,
  containerClassName = '',
  className = '',
}: PageBuilderProps) => {
  return (
    <ErrorBoundary>
      <DzColumn id={id} span={12} className={className}>
        <PageBuilderContainer innerSection={innerSection} contentLength={components.length}>
          {components?.map((component, idx) => {
            // content is the reference to the content type (artist, exhibition, article...)
            // props is the extra information required to render the component (CTA's, Overrides)
            const { _type, props, content = [] } = component

            const componentShouldRender =
              componentsCheckCustomContent?.[_type]?.(component) ?? false

            const ComponentModule = componentsIndex[_type]
            const multipleContent = ComponentModule?.multipleContentTypes ?? false
            const notContentDependant = ComponentModule?.notContentDependant ?? false
            const componentContent = multipleContent ? content : content?.[0]

            if (!ComponentModule) {
              console.warn('PAGE BUILDER::: Not supported component:', _type)
              return null
            }
            if (!componentContent && !notContentDependant && !componentShouldRender) {
              console.warn('PAGE BUILDER::: Please add content types to this component:', _type)
              return null
            }
            return (
              <div
                key={`${_type}-${idx}`}
                className={cn('flex flex-col gap-5 md:gap-10', containerClassName)}
              >
                <PageBuilderMoleculeTitle type={_type} component={component} idx={idx} />
                <div id={`molecule-${_type}-${idx}`}>
                  <ComponentModule
                    data={componentContent}
                    componentProps={props}
                    parentId={`molecule-${_type}-${idx}`}
                  />
                </div>
              </div>
            )
          })}
        </PageBuilderContainer>
      </DzColumn>
    </ErrorBoundary>
  )
}

const PageBuilderMoleculeTitle = ({
  type,
  component,
  idx,
}: {
  type: PageBuilderComponentsDataSchemaType['_type']
  component: PageBuilderComponentsDataSchemaType
  idx: number
}) => {
  // `&& component.dzMoleculeTitle` is added to check against dzMoleculeTitle being `null`
  if ('dzMoleculeTitle' in component && component.dzMoleculeTitle) {
    return !['dzEditorial', 'dzInterstitial'].includes(type) ? (
      <div id={`section-title-${type}-${idx}`}>
        <DzMoleculeTitle data={component.dzMoleculeTitle} />
      </div>
    ) : null
  }
}

/**
 * Check if the section should be displayed
 * Returns false if the components are null or empty (due to [].some(() => true)) === false)
 * Returns true if the components are not null and at least one of them should be displayed
 *
 * @param components - The components to be displayed
 * @returns boolean
 */
export const showPageBuilderSection = (
  components: (PageBuilderComponentsDataSchemaType | null)[] | null
): components is NonNullable<PageBuilderComponentsDataSchemaType>[] => {
  if (components === null) return false

  return components
    .filter((component) => component !== null)
    .some((component) => {
      const { _type } = component
      if (componentsValidators.hasOwnProperty(_type)) {
        return componentsValidators[_type](component)
      }
      return true
    })
}

export default PageBuilder
