import { DzMedia as DzMediaBasic } from '@zwirner/design-system'
import Image from 'next/image'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzMediaProps = ComponentProps<typeof DzMediaBasic>
type Props = Omit<DzMediaProps, 'LinkElement' | 'ImgElement'>

export const DzMedia = (props: Props) => {
  const dzMediaProps = { ...props, LinkElement: Link, ImgElement: Image } as DzMediaProps
  return <DzMediaBasic {...dzMediaProps} />
}

DzMedia.displayName = 'DzMediaWrapper'
